@use "../../../../styles/colors";
@use "../../../../styles/typography";

.container {
  width: 270px;
  height: 212px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid colors.$gray-border;

  .image {
    object-fit: cover
  }
}