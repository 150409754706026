@use "../../../../styles/colors";
@use "../../../../styles/typography";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .info {
    display: flex;
    justify-content: center;
    background-color: colors.$gray-background;
    border-radius: 8px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
    padding: 9px;
    margin-top: 8px;
    width: 89%;

    .NumberOfReports {
      margin: 0;
      font-size: 16px;
      font-family: typography.$font-family;
      font-weight: typography.$fontw-600;
      line-height: 20px;
      color: colors.$red;
    }
  }

  .icon {
    display: flex;
    align-self: center;
    margin-right: 8px;
    font-size: 16px;
  }

  .ViewAllReports {
    margin: 0 16px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .primaryCard2 {
    padding-top: 8px;
    padding-bottom: 8px;
    flex: 1;
    max-width: 100%;
    margin: 0 16px;
  }
}
