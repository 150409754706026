@use "../../../styles/colors";
@use "../../../styles/typography";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .cardContainer {
    display: flex;
    gap: 24px;
  }

  .navigationContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 8px;
    margin-top: 48px;

    .arrowIcon {
      display: flex;
      font-size: 24px;
      padding: 8px;
      color: colors.$gray;

      &:hover {
        cursor: pointer;
        color: colors.$white;
      }
    }

    .dotIndication {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 8px;
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 768px) {
  .container {
    .cardContainer {
      width: 768px;
      height: 100%;
      overflow: scroll;
      padding: 0 48px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .navigationContainer {
      display: none;
    }
  }
}

@media (max-width: 480px) {
  .container {
    .cardContainer {
      width: 480px;
      padding: 0 20px;
    }
  }
}

.loadMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 480px) {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.loadMoreButton {
  width: 100%;
  color: colors.$white;
  margin-right: 20px;
  margin-left: 20px;
}