@use "../../../styles/sizes";

.wrapper {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 24px;
    margin-top: 48px;
    margin-bottom: 48px;

    &::-webkit-scrollbar {
        display: none;
    }
}

.slider {
    display: none;
}


@media screen and (max-width: sizes.$breakpoint-mobile) {
    .wrapper {
        display: flex;
        width: 100vw;
        transition: all 250ms;
        align-content: center;
        padding-left: 20px;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
    }

    .slider {
        display: flex;
        width: 100%;
        height: 56px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        button {
            margin: 20px;
            font-size: 20px;
            background: none;
            border: none;
        }
    }
}

@media screen and (max-width: sizes.$breakpoint-tablet) {
    .wrapper {
        display: flex;
        width: 100vw;
        transition: all 250ms;
        align-content: center;
        padding-left: 48px;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
    }

    .slider {
        display: flex;
        width: 100%;
        height: 56px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        button {
            margin: 20px;
            font-size: 20px;
            background: none;
            border: none;
        }
    }
}