@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");

$font-family: "Montserrat", sans-serif;
$fontw-500: 500; //light
$fontw-600: 600; //medium
$fontw-700: 700; //bold
$small-font-size: 14px; //small font size
$default-font-size: 16px; //default font size
$heading-font-size: 44px; //default font size for heading
$heading-font-size-primary: 32px; //heading font size for h1
$heading-font-size-secondary: 24px; //heading font size for h2
$heading-font-size-tertiary: 18.72px; //heading font size for h3