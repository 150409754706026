@use "../../../../styles/colors";
@use "../../../../styles/typography";

.container {
  width: 127px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .info {
    display: flex;
    justify-content: center;
    width: 115px;
    background-color: colors.$gray-background;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    p {
      margin-top: 9px;
      margin-bottom: 9px;
      font-size: 16px;
      font-family: typography.$font-family;
      font-weight: typography.$fontw-500;
      line-height: 20px;
    }
  }
}

.upVote {
  color: colors.$red;
}

.downVote {
  color: colors.$green;
}
