@use '../../../styles/sizes';

.wrapper {
    display: flex;
    justify-content: center;
}

.container {
    width: 85vw;
    max-width: 1280px;
    //min-height: 1439px;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    word-wrap: break-word;
}

.heading {
    word-wrap: break-word;
    font-size: 40px;
}

.cards {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 24px;
    margin-top: 48px;
    margin-bottom: 48px;
}

.slider {
    display: none;
}


@media screen and (max-width: sizes.$breakpoint-mobile) {
    .wrapper {
        width: 100vw;
    }

    .heading {
        word-wrap: break-word;
        font-size: 40px;
        text-align: justify;
    }

    .container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .cards {
        display: flex;
        width: 100vw;
        transition: all 250ms;
        align-content: center;
        padding-left: 20px;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
    }

    .slider {
        display: flex;
        width: 100%;
        height: 56px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        button {
            margin: 20px;
            font-size: 20px;
            background: none;
            border: none;
        }
    }

    .seeAllButton {
        width: 100%;

        button {
            width: 100%;
            justify-content: center;
        }
    }
}

@media screen and (max-width: sizes.$breakpoint-tablet) {
    .wrapper {
        width: 100vw;
    }

    .container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .cards {
        display: flex;
        width: 100vw;
        transition: all 250ms;
        align-content: center;
        padding-left: 48px;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
    }

    .slider {
        display: flex;
        width: 100%;
        height: 56px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        button {
            margin: 20px;
            font-size: 20px;
            background: none;
            border: none;
        }
    }
}