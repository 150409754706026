@use "../../styles/colors";
@use "../../styles/typography";

.latestPapci {
  color: colors.$blue;
  font-family: typography.$font-family;
  font-weight: typography.$fontw-700;
  font-size: 40px;
  margin-left: 80px;
  margin-top: 30px;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    margin-left: 20px;
    font-size: 24px;
  }
}

.backLink {
  color: colors.$blue;
  font-family: typography.$font-family;
  font-weight: typography.$fontw-600;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-decoration: none;
  margin-left: 80px;
  margin-top: 20px;

  @media (max-width: 480px) {
    margin-left: 20px;
  }

  .backArrow {
    margin-right: 15px;
    width: 12px;
    height: 12px;
    fill: colors.$blue;
    margin-bottom: 5px;
  }

  span {
    line-height: 1;
  }
}

.totalApplications {
  color: colors.$light-gray;
  font-size: 22px;
  font-weight: typography.$fontw-600;
  margin-left: 80px;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    margin-left: 20px;
    font-size: 16px;
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 48px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1 0 calc(50% - 10px);

  gap: 20px;

  margin: 10px;

  @media (max-width: 1400px) {
    flex: 1 0 calc(50% - 10px);
  }

  @media (max-width: 1024px) {
    flex: 1 0 calc(50% - 10px);
  }

  @media (max-width: 768px) {
    flex: 1 0 calc(50% - 10px);
  }

  @media (max-width: 480px) {
    flex: 1 0 calc(100% - 10px);
    gap: 10px;
    margin: 5px;
  }
}

.loadMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 480px) {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.loadMoreButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: colors.$white;
  margin-right: 20px;
  margin-left: 20px;
}
