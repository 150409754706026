@use "../../../styles/colors";

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 0;
  height: 64px;

  .loadingSpinner {
    width: 64px;
    height: 64px;
    border: 8px solid colors.$yellow;
    border-top: 8px solid transparent;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
}

@media (max-width: 768px) {
  .spinnerContainer {
    margin: 12.5px 0;
  }
}

@media (max-width: 480px) {
  .spinnerContainer {
    margin: 36.7px 0;
  }
}
