@use "../../../../styles/colors";
@use "../../../../styles/typography";

.dotIndicationIcon {
  display: flex;
  font-size: 12px;
  opacity: 0.5;
  color: colors.$white;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.focused {
  opacity: 1;
  color: colors.$yellow;
}
