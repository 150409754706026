@use "../../../../styles/colors";
@use "../../../../styles/typography";
@use "../../../../styles/sizes";

.container {
  background-color: colors.$gray-background;
  font-size: typography.$default-font-size;
  font-weight: typography.$fontw-500;
  padding-left: 80px;
  padding-right: 80px;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  p {
    color: colors.$dark-blue;
    height: 24px;
  }

  .text {
    width: 270px;
    display: flex;
    justify-content: flex-end;
  }
}

.darkerTextColor {
  color: colors.$dark-blue;
}

@media (max-width: sizes.$breakpoint-tablet) {
  .container {
    flex-direction: column;
    padding-left: 55px;
    padding-right: 55px;
    height: 216px;
    justify-content: space-evenly;

    .text {
      justify-content: center;
      order: 2;
    }
  }
}
