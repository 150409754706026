@use "../../../styles/typography";
@use "../../../styles/colors";

.common {
    color: colors.$blue;
}

.h1 {
    font-size: typography.$heading-font-size-primary;
}

.h2 {
    font-size: typography.$heading-font-size-secondary;
}

.h3 {
    font-size: typography.$heading-font-size-tertiary;
}