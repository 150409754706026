@use "../../../../../styles/sizes";

.container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 12px;
}

@media screen and (max-width: sizes.$breakpoint-tablet) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
}

@media screen and (max-width: sizes.$breakpoint-mobile) {
  .container {
    grid-template-columns: auto;
  }
}
