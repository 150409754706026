$white: #ffffff; // default white
$blue: #2d3480; //primary blue
$dark-blue: #111434; // dark blue
$dim-dark-blue: #1114341a; // dark blue with 10% opacity
$bare-dark-blue: #1114340d; // dark blue with 5% opacity
$blue-gradient-light: #444b98; //light blue
$blue-gradient-lighter: #575c99; //lightest blue
$blue-gradient-dark: #290849; //dark blue
$primary-button-hover: #42478c; //primary button hover state color
$primary-button-focus: #575c99; //primary button focus state color
$secondary-button-hover: #1114340d; //secondary button hover state color
$secondary-button-focus: #1114341a; //secondary button focus state color
$blue-license-plate: #024bd5; //blue color on license plate
$yellow: #ffd647; //accent yellow
$gray: #999999; //gray color
$gray-background: #edf2f5; //background gray
$gray-border: #d0d5dd; //border gray
$gray-text: #667085; // text gray
$heading-text: $white; // heading color
$paragraph-text: $dark-blue; // paragraph color
$green: #00b047; // success green
$red: #d81f2c; // error red
$light-gray: #999999; // lighter gray
