@use "../../../styles/colors";
@use "../../../styles/typography";

.searchBar {
  display: flex;
  height: 44px;
  width: 100%;

  .searchIcon {
    display: flex;
    font-size: 20px;
    position: relative;

    svg {
      position: absolute;
      left: 14px;
      top: 12px;
    }
  }

  input {
    box-sizing: border-box;
    width: 85%;
    border: 1px solid colors.$gray-border;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px 0px 0px 8px;
    display: flex;
    text-indent: 30px;
    padding: 10px 14px;
    font-family: typography.$font-family;
    font-weight: typography.$fontw-500;
    font-size: typography.$default-font-size;
    line-height: 24px;

    &:hover {
      border: 1px solid colors.$gray-text;
      cursor: pointer;
    }

    &:focus {
      outline: none;
      border: 1px solid colors.$yellow;
    }
  }

  button {
    background: colors.$yellow;
    color: colors.$blue;
    border: 1px solid colors.$gray-border;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 0px 8px 8px 0px;
    padding: 10px 18px;
    font-family: typography.$font-family;
    font-weight: typography.$fontw-600;
    font-size: typography.$default-font-size;
    line-height: 24px;

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(17, 20, 52, 0.05) 0%,
          rgba(17, 20, 52, 0.05) 100%
        ),
        colors.$yellow;
      cursor: pointer;
    }

    &:active {
      background: linear-gradient(
          0deg,
          rgba(17, 20, 52, 0.1) 0%,
          rgba(17, 20, 52, 0.1) 100%
        ),
        colors.$yellow;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .searchBar {
    flex-direction: column;

    input {
      border-radius: 10px;
      width: 100%;
    }

    button {
      border-radius: 10px;
      margin-top: 16px;
    }
  }
}
