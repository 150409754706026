@use "../../../styles/colors";
@use "../../../styles/typography";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .wrapper {
    display: flex;
    width: 100%;
    position: relative;

    .contentWrapper {
      overflow: hidden;
      width: 100%;
      height: 100%;

      .content {
        display: flex;
        transition: all 250ms linear;
        height: 100%;

        & > * {
          flex-shrink: 0;
          flex-grow: 1;
        }
      }
    }

    .leftArrow,
    .rightArrow {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: colors.$white;
      border: none;
      opacity: 90%;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        opacity: 100%;
        cursor: pointer;
      }
    }

    .leftArrow {
      left: 8px;
    }

    .rightArrow {
      right: 8px;
    }
  }
}
