@use "../../../styles/colors";
@use "../../../styles/typography";

.text {
  font-family: typography.$font-family;
  font-size: typography.$default-font-size;
  color: colors.$blue;
  height: fit-content;
}

.bold {
  font-weight: typography.$fontw-600;
}
