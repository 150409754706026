@use "../../../styles/colors";
@use "../../../styles/typography";

.button {
  font-family: typography.$font-family;
  font-weight: typography.$fontw-600;
  font-size: typography.$default-font-size;
  line-height: 24px;
  padding: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  border: 1px solid colors.$gray-border;
  color: colors.$blue;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  .icon {
    padding-right: 8px;
    display: flex;
  }

  .cardButtonText {
    color: colors.$white;
    font-size: typography.$small-font-size;
  }

  .inverseTextColor {
    color: colors.$white;
  }
}

.primary {
  background-color: colors.$blue;

  &:hover {
    background-color: colors.$blue-gradient-light;
  }

  &:focus {
    background-color: colors.$blue-gradient-lighter;
  }
}

.secondary {
  background-color: colors.$white;

  &:hover {
    border-color: colors.$gray-border;
    background-color: colors.$bare-dark-blue;
  }

  &:focus {
    border-color: colors.$gray-border;
    background-color: colors.$dim-dark-blue;
  }
}

.tertiary {
  background-color: transparent;
  border-color: transparent;

  &:hover {
    border-color: colors.$gray-border;
    background-color: colors.$bare-dark-blue;
  }

  &:focus {
    border-color: colors.$gray-border;
    background-color: colors.$dim-dark-blue;
  }
}

.primaryCard {
  display: flex;
  justify-content: center;
  width: 127px;
  font-weight: typography.$fontw-500;
  font-size: typography.$small-font-size;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: colors.$blue;

  &:hover {
    background-color: colors.$primary-button-hover;
  }

  &:focus {
    background-color: colors.$primary-button-focus;
  }
}

.tertiaryCard {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent;
  border-color: transparent;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
}
