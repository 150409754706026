@use "../../../../styles/colors";
@use "../../../../styles/typography";

.container {
  width: 130px;
  height: 32px;
  border-radius: 5px;
  border: 2px solid black;
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 170px;

  .side {
    width: 16px;
    background-color: colors.$blue-license-plate;
    display: flex;
    justify-content: center;

    p {
      font-family: typography.$font-family;
      font-weight: typography.$fontw-600;
      font-size: 5px;
      color: colors.$heading-text;
      align-self: flex-end;
    }
  }

  .content {
    width: 114px;
    background-color: colors.$heading-text;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-family: typography.$font-family;
      font-weight: typography.$fontw-500;
      font-size: 16px;
      color: colors.$paragraph-text;
    }
  }
}
