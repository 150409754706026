@use "../../../../../styles/colors";
@use "../../../../../styles/sizes";

.container {
  display: flex;
  width: 92.57px;
  height: 24px;
  justify-content: space-around;
  align-items: center;

  svg {
    margin-left: 12px;
    margin-right: 12px;
  }

  a {
    display: inline-block;
    color: colors.$dark-blue;
    font-size: 16px;
  }
}

@media (max-width: sizes.$breakpoint-tablet) {
  .container {
    width: 162.86px;
    height: 40px;

    a {
      color: #2d2d2d;
      font-size: 24px;
    }
  }
}
