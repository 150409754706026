@use "../../../styles/colors";
@use "../../../styles/typography";
@use "../../../styles/sizes";

.wrapper {
  display: flex;
  justify-content: center;

  .content {
    width: 100%;
    max-width: 1280px;
    background: linear-gradient(colors.$blue-gradient-light,
        colors.$blue-gradient-dark);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 80px;
    overflow: hidden;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 624px;
      height: 480px;
      min-height: 480px;
      align-self: center;
      padding: 135px 0 0;
      z-index: 1;

      h1 {
        font-family: typography.$font-family;
        font-weight: typography.$fontw-700;
        font-size: typography.$heading-font-size;
        line-height: 54px;
        text-align: center;
        color: colors.$heading-text;
        margin-top: 0;
        margin-bottom: 64px;
      }

      .topSearch {
        width: 624px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .searchContainer {
          width: 954px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 48px;

          .searchText {
            font-family: typography.$font-family;
            font-weight: typography.$fontw-600;
            font-size: typography.$default-font-size;
            color: colors.$white;
            margin-top: 0;
            margin-bottom: 48px;

            span {
              color: colors.$yellow;
            }
          }

          .searchHeading {
            font-family: typography.$font-family;
            font-weight: typography.$fontw-700;
            font-size: typography.$heading-font-size-secondary;
            color: colors.$white;
            margin: 0;
            margin-top: -24px;
          }
        }
      }

      .topImage {
        display: flex;
        font-size: 620px;
        position: relative;

        svg {
          position: absolute;
          right: -310px;
          top: -325px;
          z-index: -1;
        }
      }
    }

    .bottom {
      width: 100%;
      padding: 64px 0;
      background-color: colors.$gray-background;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      z-index: 1;

      .bottomContent {
        width: 780px;
        padding-left: 80px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .buttonContainer {
          .reportPapakButton {
            border-color: #7F56D9;
          }
        }

        p {
          color: colors.$paragraph-text;
          font-family: typography.$font-family;
          font-weight: typography.$fontw-500;
          font-size: typography.$default-font-size;
          line-height: 24px;
          margin-top: 0;
          margin-bottom: 40px;
        }
      }

      .bottomImage {
        display: flex;
        font-size: 318.05px;

        svg {
          position: relative;
          right: -70px;
        }
      }
    }
  }
}

.expand {
  height: fit-content !important;
  padding-bottom: 64px !important;
}

@media (max-width: sizes.$breakpoint-tablet) {
  .wrapper {
    .content {
      width: 100%;
      border-radius: 0;
      margin: 0;

      .top {
        width: 499px;
        padding-bottom: 200px;

        h1 {
          width: 499px;
          font-size: typography.$heading-font-size-primary;
          line-height: 39px;
        }

        .topSearch {
          width: 100%;

          .searchContainer {
            width: 100%;
            margin-top: 140px;

            .searchText {
              text-align: center;
              margin-bottom: 32px;
            }

            .searchHeading {
              text-align: center;
              padding-top: 32px;
            }
          }
        }

        .topImage {
          font-size: 400px;

          svg {
            position: absolute;
            right: -200px;
            top: -210px;
            z-index: -1;
          }
        }
      }

      .bottom {
        flex-direction: column-reverse;
        width: 100%;
        padding: 80px 134px;
        box-sizing: border-box;

        .bottomContent {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          padding: 0;

          p {
            margin-top: 0;
            margin-bottom: 40px;
          }

          .buttonContainer {
            width: 328px;
            display: flex;
            flex-direction: column;
          }
        }

        .bottomImage {
          display: flex;
          font-size: 256px;
          margin-bottom: 40px;

          svg {
            position: static;
          }
        }
      }
    }
  }
}

@media (max-width: sizes.$breakpoint-mobile) {
  .wrapper {
    .content {
      .top {
        width: 335px;

        h1 {
          width: 266px;
          margin-bottom: 48px;
        }

        .topImage {
          font-size: 300px;

          svg {
            right: -150px;
            top: -230px;
          }
        }
      }

      .bottom {
        padding: 80px 20px;

        .bottomContent {
          align-items: normal;

          .buttonContainer {
            width: 100%;

            .reportPapakButton {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}