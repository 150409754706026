@use "../../../../styles/colors";
@use "../../../../styles/typography";

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 100px;
}

.arrowButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    svg path,
    svg polygon,
    svg rect,
    svg circle {
      fill: colors.$blue;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    fill: colors.$light-gray;
    margin-top: 0px;
  }
}

.arrowButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    svg path,
    svg polygon,
    svg rect,
    svg circle {
      fill: colors.$blue;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    fill: colors.$light-gray;
    margin-top: 5px;
  }
}

.pageButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: colors.$light-gray;
  font-weight: typography.$fontw-700;

  &:hover {
    color: colors.$blue;
  }
}

.active {
  color: colors.$blue;
}
