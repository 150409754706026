@use "../../../styles/sizes";

.top {
    font-size: 48px;
    position: fixed;
    right: 80px;
    bottom: 80px;

    &:hover {
        cursor: pointer;
    }

    &.visible {
        display: block;
    }

    display: none;
}

@media screen and (max-width: sizes.$breakpoint-tablet) {
    .top {
        right: 48px;
        bottom: 80px;
    }
}

@media screen and (max-width: sizes.$breakpoint-mobile) {
    .top {
        right: 20px;
        bottom: 80px;
    }
}