@use "../../../styles/colors";
@use "../../../styles/typography";
@use "../../../styles/sizes";

* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  margin: 0;
}

.header {
  width: 100%;
  height: 100px;
  align-items: center;
  background-color: colors.$heading-text;
  display: flex;
  overflow: hidden;

  @media screen and (max-width: sizes.$breakpoint-mobile) {
    /* Styles for mobile */
    height: 80px;
  }
}

.logo {
  display: flex;
  margin-left: 80px;

  margin-top: 7px;

  img {
    width: 49px;
    height: 49px;
  }

  @media screen and (max-width: sizes.$breakpoint-mobile) {
    /* Styles for mobile */
    margin-left: 10px;
    margin-top: 5px;

    svg {
      width: 35px;
      height: 35px;
    }
  }
}

.wallOfShame {
  margin: 20px;
  align-items: center;
  font-size: 40px;
  font-weight: 700;
  color: colors.$blue;
  margin-top: 30px;

  @media screen and (max-width: sizes.$breakpoint-mobile) {
    /* Styles for mobile */
    font-size: 28px;
    margin-top: 23px;
    white-space: nowrap;
    margin-right: 5px;
    margin-left: 12px;
    font-weight: 750;
  }
}

.headerButton {
  margin-left: auto;
  margin-right: 80px;

  .desktopButton {
    display: flex;
  }

  .mobileButton {
    display: none;
  }

  @media screen and (max-width: sizes.$breakpoint-mobile) {
    padding: 10px 18px;
    margin-right: -2px;
    margin-top: 6px;

    .desktopButton {
      display: none;
    }

    .mobileButton {
      display: flex;
    }
  }
}

.headerText {
  white-space: nowrap;
}
