@use "../../../../styles/colors";
@use "../../../../styles/sizes";

$top-icon-width: 64px;
$top-icon-height: 64px;

.container {
  position: relative;
  padding: 80px;
  padding-top: 120px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    display: flex;
    font-size: 256px;
  }
}

.top {
  position: absolute;
  right: 80px;
  top: 40px;
  background: none;
  padding: 0;
  border: none;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: $top-icon-width;
    height: $top-icon-height;
  }
}

@media (max-width: sizes.$breakpoint-tablet) {
  .container {
    flex-direction: column;
  }

  .top {
    right: 20px;
    top: 276px;
  }
}

@media screen and (max-width: sizes.$breakpoint-mobile) {
  .container {
    flex-direction: column;
  }

  .top {
    right: 20px;
    top: 276px;
  }
}
