@use '../../../../styles/colors';
@use "../../../../styles/sizes";

.container {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 64px;
  padding-bottom: 64px;
  background-color: colors.$dark-blue;
  color: colors.$white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  a {
    color: colors.$white;
  }

  li {
    margin-bottom: 12px;
  }
}

.heading {
  color: colors.$white;
}

.headingContainer {
  margin-bottom: 40px;
}

.readMoreContainer {
  margin-top: 40px;
}

.inverseTextColor {
  color: colors.$white;
}

@media screen and (max-width: sizes.$breakpoint-tablet) {
  .container {
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media screen and (max-width: sizes.$breakpoint-mobile) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}