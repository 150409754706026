@use "../../../styles/colors";
@use "../../../styles/typography";

.container {
  width: 302px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //padding-top: 16px;
  padding: 10px;
  border: 1px solid colors.$gray-border;
  border-radius: 8px;
  background-color: colors.$white;

  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .content {
    width: 270px;
    height: 76px;
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .paragraph {
      display: flex;
      align-items: center;

      .icon {
        display: flex;
        align-self: flex-start;
        margin-right: 8px;
      }

      a {
        text-decoration: none;
      }

      p {
        margin: 0;
        font-size: 16px;
        font-family: typography.$font-family;
        font-weight: typography.$fontw-500;
        color: colors.$paragraph-text;
      }
    }

    .location {
      margin-bottom: 32px;
      width: 270px;
      height: 40px;

      &:hover {
        cursor: pointer;
      }
    }

    .dateTime {
      display: flex;

      .date {
        margin-right: 40px;
      }
    }
  }

  .voting {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid colors.$gray-border;
    border-bottom: 1px solid colors.$gray-border;
    margin-top: auto;
  }

  .allReports {
    width: 100%;
    padding-top: 6px;
    padding-bottom: 16px;
    border-top: 1px solid colors.$gray-border;
    border-bottom: 1px solid colors.$gray-border;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 12px 18px 0px rgba(17, 20, 52, 0.1);
    border-color: lightgray;
    border-width: 1px;
  }

  .reportViolationButton {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.text {
  color: #111434;
}
